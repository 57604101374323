<template>
  <div class="Home">
    <SimpleHeader />

    <div class="ContentWrap">
      <div class="LoginWrap">
        <span class="Title">{{ mixWB('LOG_IN') }}</span>
        <span>{{ mixWB('IF_YOU_HAVE_A_USER_YOU_CAN_LOG_IN_HERE') }}</span>
        <div class="FormWrap">
          <HomeSignInForm @email-sent="onEmailSent" />
        </div>
        <span class="Title">{{ mixWB('DONT_HAVE_A_USER') }}</span>
        <span
          v-html="mixWB('WRITE_OR_CALL_US_TEXT', [
            contactInfo.email,
            contactInfo.phone,
          ])"/>
      </div>

      <div class="InfoBox">
        <p
          class="Title"
          v-html="mixWB('INFO_BOX_TITLE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_2')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_3')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_2')"/>
        <p v-html="mixWB('INFO_BOX_LINE_4')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_3')"/>
        <p v-html="mixWB('INFO_BOX_LINE_5')"/>
        <p v-html="mixWB('INFO_BOX_LINE_6')"/>
        <p v-html="mixWB('INFO_BOX_LINE_7')"/>
        <p v-html="mixWB('INFO_BOX_LINE_8')"/>
      </div>
    </div>

    <Dialog
      :isShowing="showEmailSentDialog"
      :useComponent="LoginEmailSentModal"
      :easyClose="false"
      @close="showEmailSentDialog = false" />
  </div>
</template>

<script>
import HomeSignInForm from '@/components/Forms/HomeSignInForm.vue'
import Dialog from '@/components/Modals/Dialog'
import LoginEmailSentModal from '@/components/LoginEmailSentModal'
import SimpleHeader from '@/components/SimpleHeader'
import { markRaw } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      LoginEmailSentModal: markRaw(LoginEmailSentModal),
      showEmailSentDialog: false,
    }
  },
  computed: {
    ...mapGetters([
      'contactInfo',
    ]),
  },
  methods: {
    onEmailSent() {
      this.showEmailSentDialog = true
    },
  },
  components: {
    HomeSignInForm,
    Dialog,
    SimpleHeader,
  },

}
</script>

<style lang="stylus" scoped>
  .Home
    display block
    padding-bottom 100px

  .ContentWrap
    display flex
    justify-content center
    align-items flex-start

  .InfoBox
    width 570px
    padding 30px 30px 50px
    background-color $color_green
    margin-left 90px
    p
      color #fff
      padding-bottom 15px
    .Title
      font-size 1.5rem
      font-weight bold
    .SubLine
      font-size 0.875rem
      font-style italic
      color $color_grey_dark

  .LoginWrap
    width 570px
    margin-top 110px
    padding 30px 30px 70px
    background-color rgba($color_grey_lightest, 0.8)
    span
      text-align center
      padding-bottom 15px
    .Title
      font-size 1.5rem
      font-weight bold
      padding-bottom 10px
    .FormWrap
      margin 0 auto 40px
      width 380px
</style>
